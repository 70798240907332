<template>
  <div>
    <p class="promo promo_blocks">{{ question | localize }}</p>
    <div class="service__container">
      <button type="button" class="service service_question service_question_1" @click="saveAndGo(1)">
        <div class="service__title">{{ "yes" | localize }}</div>
      </button>
      <button type="button" class="service service_question service_question_2" @click="saveAndGo(0)">
        <div class="service__title">{{ "no" | localize }}</div>
      </button>
    </div>
  </div>
</template>

<script>
import { commonMixin } from '@/mixins/commonMixin.js'

export default {
  name: 'Question',
  data: () => ({ nextlink: null }),
  mixins: [commonMixin],
  props: {
    name: { type: String, required: true },
    service: { type: String, required: true },
    question: { type: String, required: true },
    successUrl: { type: String, required: true },
    negativeUrl: { type: String, required: true },
    droppedSteps: { type: Array, default: () => [] },
    successMissedSteps: { type: Array, default: () => [] },
    negativeMissedSteps: { type: Array, default: () => [] }
  },
  methods: {
    saveAndGo (value) {
      // Сброс следующих шагов линии действий
      if (this.droppedSteps.length > 0) {
        this.dropSteps(this.service, this.$router.currentRoute.path, this.droppedSteps[0])
      }

      // Ответ "Да"
      if (value === 1) {
        this.nextlink = this.successUrl
        if (this.successMissedSteps.length > 0) {
          this.addMissedSteps(this.service, this.successMissedSteps)
        }
      } 
      // Ответ "Нет"
      else {
        this.nextlink = this.negativeUrl
        if (this.negativeMissedSteps.length > 0) {
          this.addMissedSteps(this.service, this.negativeMissedSteps)
        }
      }

      const service = this.$cookies.get(this.service) || {}
      service[this.name] = value
      this.$cookies.set(this.service, service)
      this.$router.push(this.nextlink)
    }
  },
}
</script>
