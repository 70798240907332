<template>
  <div class="form">
    <div class="wrapper">
      <Backlink title="questionnaire" service="refCredit" backlink="/refinance-credit/income" />
      <div class="content">
        <div class="content__container">
          <Question
            service="refCredit"
            name="add_expense"
            question="question_2"
            successUrl="/refinance-credit/about-expenses"
            negativeUrl="/refinance-credit/obligations"
            :droppedSteps="['/refinance-credit/about-expenses']"
            :negativeMissedSteps="['/refinance-credit/about-expenses']"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import Question from '@/components/Question.vue'
export default {
  name: 'Refinance_credit_expense',
  components: { Backlink, Question }
}
</script>
